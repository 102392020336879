@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Montserrat&display=swap");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  background: linear-gradient(
    rgb(0, 0, 0),
    8%,
    rgb(5, 29, 24),
    92%,
    rgb(0, 0, 0)
  );
  background-attachment: fixed;
  background-repeat: no-repeat;
}

body {
  color: #afafaf;
  display: flex;
  justify-content: center;
  min-height: 100%;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  width: 350px;
}

h1,
h2 {
  font-family: "Major Mono Display", sans-serif;
  text-align: center;
}

h1 {
  font-size: 36px;
  margin: 40px 0;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

button {
  color: rgb(0, 0, 0);
  font-weight: bold;
  background-color: rgb(71, 128, 88);
  font-family: "Montserrat", sans-serif;
  margin: 20px 10px 0 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgb(25, 97, 84);
  transition: all 0.5s ease-in;
}

button:focus {
  outline: none;
}

.App {
  height: 100%;
}

.media-module {
  box-shadow: 0px 0px 10px rgba(117, 117, 117, 0.75);
  border-radius: 5px;
  padding: 20px;
  margin: 0 10px 20px 10px;
}

.transparent {
  opacity: 0;
}

.media-modules {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.disconnect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 10px 20px;
}

.spotify-logo {
  display: inline;
  font-size: 24px;
  margin: 0 10px 0 0;
}

.button-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
}

.button-container {
  width: 100%;
  margin: 20px 0 40px 0;
  display: flex;
  justify-content: center;
}
