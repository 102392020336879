#youtube-playlist-player {
  height: auto;
  width: auto;
}

.eye-icon {
  margin-left: 5px;
}

.youtube-player {
  justify-content: space-between;
}

.ambience-tracks {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.selected {
  background-color: rgb(49, 35, 59);
  color: rgb(175, 175, 175);
  box-shadow: 0px 0px 8px rgb(110, 73, 136);
}

.player-controls {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.player-controls i {
  text-align: center;
}

.add-track {
  z-index: 10;
  top: 20px;
  position: absolute;
  background-color: rgba(49, 35, 59, 1);
  padding: 10px;
  transition: opacity 0.5s ease-out;
  border-radius: 5px;
}

.add-track input {
  text-align: center;
  display: block;
  height: 30px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.hidden {
  opacity: 0;
  z-index: -10;
  transition:
    opacity 0.5s ease-out,
    z-index 0s ease-out 0.5s;
}

.track-buttons {
  line-height: 36px !important;
  margin: 20px 20px 0 20px !important;
}

.no-display {
  display: none !important;
}
