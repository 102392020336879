.volume-controls {
  display: flex;
  justify-content: center;
  height: 60px;
}

.dim {
  color: rgba(117, 117, 117, 0.5);
}

.range {
  transition: all 1s ease-out;
}

.dim-range {
  opacity: 0.17;
}
